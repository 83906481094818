import { createRouter, createWebHistory } from "vue-router";
import { pageRoutes, emailRoutes } from "@/../routes-config.yml";
import store from "../store";

const pr = pageRoutes;

const routes = [
  {
    path: `${emailRoutes.passwordCreateToken}/:token`,
    name: "CatchEmailCreate",
    beforeEnter: (to, from, next) => {
      store.dispatch("UpdateEmailToken", to.params.token);
      next({ name: "PasswordCreate" });
    }
  },
  {
    path: `${emailRoutes.passwordRestoreToken}/:token`,
    name: "CatchEmailRestore",
    beforeEnter: (to, from, next) => {
      store.dispatch("UpdateEmailToken", to.params.token);
      next({ name: "PasswordRestore" });
    }
  },
  {
    path: pr.main,
    name: "Main",
    component: () => import("../views/Main.vue"),
    meta: {
      seoName: "main"
    },
    beforeEnter: (to, from, next) => {
      store.commit("User", null);
      next();
    }
  },
  {
    path: pr.login,
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      seoName: "login"
    },
    beforeEnter: (to, from, next) => {
      store.commit("User", null);
      next();
    }
  },
  {
    path: pr.passwordRecovery,
    name: "PasswordRecovery",
    component: () => import("../views/Password-recovery.vue"),
    meta: {
      seoName: "passwordRecovery"
    },
    beforeEnter: (to, from, next) => {
      store.commit("User", null);
      next();
    }
  },
  {
    path: pr.passwordCreate,
    name: "PasswordCreate",
    component: () => import("../views/Password-create.vue"),
    meta: {
      seoName: "passwordCreate"
    },
    beforeEnter: (to, from, next) => {
      store.commit("User", null);
      next();
    }
  },
  {
    path: pr.passwordRestore,
    name: "PasswordRestore",
    component: () => import("../views/Password-restore.vue"),
    meta: {
      seoName: "passwordRestore"
    },
    beforeEnter: (to, from, next) => {
      store.commit("User", null);
      next();
    }
  },
  {
    path: pr.signUp,
    name: "SignUp",
    component: () => import("../views/Sign-up.vue"),
    meta: {
      seoName: "signUp"
    },
    beforeEnter: (to, from, next) => {
      store.commit("User", null);
      next();
    }
  },
  {
    path: pr.settings,
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      seoName: "settings"
    },
    beforeEnter: async (to, from, next) => {
      try {
        if (!store.getters.SETTINGS_TRANSLATE) await store.dispatch("SettingsTranslate");
        if (!store.getters.VALIDATION_TRANSLATE) await store.dispatch("ValidationTranslate");
      } catch (error) {
        console.log(error);
        store.dispatch("SummonAlert", { message: store.getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
        next({ name: "Login" });
      }
      next();
    }
  },
  {
    path: pr.dishes,
    name: "Dishes",
    component: () => import("../views/Dishes.vue"),
    meta: {
      seoName: "dishes"
    },
    beforeEnter: async (to, from, next) => {
      try {
        store.commit("Set_Id", "navDishes");
      } catch (error) {
        console.log(error);
      }
      next();
    }
  },
  {
    path: pr.payment,
    name: "Payment",
    component: () => import("../views/Payment.vue"),
    meta: {
      seoName: "payment"
    }
  },
  {
    path: pr.orderHistory,
    name: "OrderHistory",
    component: () => import("../views/Order-history.vue"),
    meta: {
      seoName: "orderHistory"
    },
    beforeEnter: async (to, from, next) => {
      try {
        store.commit("Set_Id", "navOrders");
      } catch (error) {
        console.log(error);
      }
      next();
    }
  },
  {
    path: pr.employees,
    name: "Employees",
    component: () => import("../views/Employees.vue"),
    meta: {
      seoName: "employees"
    },
    beforeEnter: async (to, from, next) => {
      store.commit("Set_Id", "navEmployees");
      if (!store.getters.USER) {
        await store.dispatch("AboutMe");
      }
      if (store.getters.USER.role.id == 2) return next();
      next("/404");
    }
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorPage",
    component: () => import("../views/Page-404.vue"),
    meta: {
      seoName: "notFound"
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.TOKEN ?? localStorage.getItem("token");
  const isPayment = localStorage.getItem("isPayment");
  const withoutToken = ["Login", "Test", "PasswordRestore", "PasswordRecovery", "PasswordCreate", "CatchEmailCreate", "CatchEmailRestore", "Main", "SignUp", "ErrorPage"];
  const withTokenDef = ["Login", "PasswordRestore", "PasswordRecovery", "PasswordCreate", "CatchEmailCreate", "CatchEmailRestore", "Main", "SignUp"];
  const withPayment = ["Payment"];

  store.commit("Set_Links", false);
  if (!store.getters.ROUTERS) await store.dispatch("GetRouters");
  if (!store.getters.SEO) await store.dispatch("GetSeo");
  if (to?.meta?.seoName) {
    const pageTitle = store.getters.SEO?.[to?.meta?.seoName]?.[0]?.title;
    const pageOgTitle = store.getters.SEO?.[to?.meta?.seoName]?.[0]?.open_graph?.title || "";
    const pageOgDescription = store.getters.SEO?.[to?.meta?.seoName]?.[0]?.open_graph?.description || "";
    const pageOgImage = store.getters.SEO?.[to?.meta?.seoName]?.[0]?.open_graph?.image?.url || "";

    document.title = pageTitle || "";

    const metaDes = document.querySelector('meta[name="description"]');
    metaDes.content = store.getters.SEO?.[to?.meta?.seoName]?.[0]?.description || "";

    const ogTitle = document.querySelector("meta[property='og:title']");
    ogTitle.content = pageOgTitle;

    const ogDescription = document.querySelector("meta[property='og:description']");
    ogDescription.content = pageOgDescription;

    const ogImage = document.querySelector("meta[property='og:image']");
    ogImage.content = pageOgImage;

    if (to?.meta?.seoName == "main") {
      const metaKW = document.createElement("meta");
      metaKW.name = "keywords";
      metaKW.content = store.getters.SEO?.[to?.meta?.seoName]?.[0]?.keywords || "";
      document.getElementsByTagName("head")[0].appendChild(metaKW);

      const metaCanon = document.createElement("link");
      metaCanon.rel = "canonical";
      metaCanon.href = store.getters.SEO?.[to?.meta?.seoName]?.[0]?.canonical || "";
      document.getElementsByTagName("head")[0].appendChild(metaCanon);
    } else {
      const metaCanon = document.querySelector('link[rel="canonical"]');
      const metaKW = document.querySelector('meta[name="keywords"]');

      metaCanon ? document.head.removeChild(metaCanon) : "";
      metaKW ? document.head.removeChild(metaKW) : "";
    }
  } else {
    document.title = "";
  }

  if (!withoutToken.includes(to.name)) {
    if (!store.getters.USER) await store.dispatch("AboutMe");
    await store.dispatch("RefreshToken");
  }
  if (!withoutToken.includes(to.name) && !isAuthenticated) next({ name: "Login" });
  if (withTokenDef.includes(to.name) && isAuthenticated) next({ name: "Dishes" });
  if (withPayment.includes(to.name) && !isPayment) next({ name: "Dishes" });
  next();
});

export default router;
