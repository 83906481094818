import { createApp } from "vue";
import App from "./App.vue";
import VueSplide from "@splidejs/vue-splide";
import router from "./router";
import store from "./store";
import "./assets/style/global.scss";
import VueSmoothScroll from "vue3-smooth-scroll";
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueSplide);
app.use(VueSmoothScroll);
app.use(Vue3TouchEvents);

app.directive("lazyload", {
  mounted(el) {
    function loadImage() {
      const imageElement = Array.from(el.children).find(el => el.nodeName === "IMG");
      if (imageElement) {
        imageElement.addEventListener("error", () => console.log("error"));
        imageElement.src = imageElement.dataset.src;
        setTimeout(() => {
          imageElement.style.cursor = 'pointer';
        }, 100);
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: "0"
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }

    if (window["IntersectionObserver"]) {
      createObserver();
    } else {
      loadImage();
    }
  },
  updated(el) {
    function loadImage() {
      const imageElement = Array.from(el.children).find(el => el.nodeName === "IMG");
      if (imageElement) {
        imageElement.addEventListener("error", () => console.log("error"));
        imageElement.src = imageElement.dataset.src;
        setTimeout(() => {
          imageElement.style.cursor = 'pointer';
        }, 100);
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: "0"
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }

    if (window["IntersectionObserver"]) {
      createObserver();
    } else {
      loadImage();
    }
  }
});

app.directive("click-outside", {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app.mount("#app");
