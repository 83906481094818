import api from "@/helpers/api";

export default {
  state: {
    orders: null,
    periods: null,
    me: null
  },
  getters: {
    GET_ORDER_LIST: state => {
      return state.orders;
    },
    GET_PERIODS: state => {
      return state.periods;
    },
    GET_MY_ROLE: state => {
      return state.me;
    }
  },
  mutations: {
    Set_Order_List(state, payload) {
      state.orders = payload;
    },
    Set_Periods(state, payload) {
      state.periods = payload;
    },
    Set_My_Role(state, payload) {
      state.me = payload;
    }
  },
  actions: {
    async SetOrderList({ commit, dispatch, getters }, payload) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let url = null;
        if (getters.USER.role.id == 2) {
          url = getters.ROUTERS.ordersCompleted.replace("{companyId}", getters.USER.company.id) + `?period=${payload.period}`;
        } else {
          url = getters.ROUTERS.ordersCompletedByUser.replace("{companyId}", getters.USER.company.id).replace("{employee}", getters.USER.id) + `?period=${payload.period}`;
        }
        let res = await api.get(url);
        let resArr = Object.values(res.data);
        resArr.forEach(item => {
          item.show = false;
        });
        commit("Set_My_Role", getters.USER.role.id);
        commit("Set_Order_List", resArr);
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async SetPeriods({ commit, dispatch, getters }) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let url = getters.ROUTERS.ordersCompletedPeriods.replace("{companyId}", getters.USER.company.id);
        let res = await api.get(url);
        let resArr = Object.entries(res.data);
        let newArr = [];
        resArr.forEach((item, i) => {
          newArr[i] = {
            active: i == 0 ? true : false,
            value: item[0],
            text: item[1]
          };
        });
        commit("Set_Periods", newArr);
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    }
  }
};
