import { createStore } from "vuex";
import auth from "./modules/auth";
import setting from "./modules/setting";
import signUp from "./modules/signUp";
import alert from "./modules/alert";
import home from "./modules/home";
import employee from "./modules/employee";
import employees from "./modules/employees";
import password from "./modules/password";
import limit from "./modules/limit";
import translate from "./modules/translate";
import roles from "./modules/roles";
import dishes from "./modules/dishes";
import orders from "./modules/orders";
import ordersList from "./modules/ordersList";
import exportOrder from "./modules/exportOrder";
import navbar from "./modules/navbar";
import payment from "./modules/payment";
import seo from "./modules/seo";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    alert,
    home,
    setting,
    signUp,
    employee,
    employees,
    password,
    limit,
    translate,
    roles,
    dishes,
    orders,
    ordersList,
    exportOrder,
    navbar,
    payment,
    seo
  }
});
