import api from "@/helpers/api";

export default {
  actions: {
    async AddEmployee({ dispatch, getters }, payload) {
      try {
        if (!getters.EMPLOYEES_TRANSLATE) await dispatch("EmployeesTranslate");
        let url = getters.ROUTERS.employeesIndex.replace("{companyId}", payload.company);
        await api.post(url, payload.temp);
        dispatch("EmployeesList");
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.create.success });
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.create.failed, type: "error" });
      }
    },
    async EditEmployee({ dispatch, getters }, payload) {
      try {
        if (!getters.EMPLOYEES_TRANSLATE) await dispatch("EmployeesTranslate");
        let url = getters.ROUTERS.employeesUpdate.replace("{employee}", payload.user).replace("{companyId}", payload.company);
        await api.put(url, payload.temp);
        await dispatch("AboutMe");
        await dispatch("EmployeesList");
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.edit.success + " " + payload.temp.name + " " + payload.temp.last_name + " " + getters.EMPLOYEES_TRANSLATE.list.alert.employee.edit.success_two });
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.edit.failed, type: "error" });
      }
    },
    async DeleteEmployee({ dispatch, getters }, payload) {
      try {
        if (!getters.EMPLOYEES_TRANSLATE) await dispatch("EmployeesTranslate");
        let url = getters.ROUTERS.employeesUpdate.replace("{companyId}", payload.company).replace("{employee}", payload.user);
        await api.delete(url);
        dispatch("EmployeesList");
        dispatch("SummonAlert", { message: payload.name + " " + payload.last_name + " " + getters.EMPLOYEES_TRANSLATE.list.alert.employee.delete, type: "error", decline: true, data: payload });
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.edit.failed, type: "error" });
      }
    },
    async RestoreEmployee({ dispatch, getters }, payload) {
      try {
        let url = getters.ROUTERS.employeesRestore.replace("{companyId}", payload.company).replace("{employee}", payload.user);
        await api.put(url);
        dispatch("EmployeesList");
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.edit.failed, type: "error" });
      }
    }
  }
};
