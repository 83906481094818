import api from "@/helpers/api";

export default {
  state: {
    orders: null,
    dayComplete: false,
  },
  getters: {
    ORDERS: (state) => {
      return state.orders;
    },
    DAY_COMPLETE: (state) => {
      return state.dayComplete;
    },
  },
  mutations: {
    GetOrders(state, payload) {
      state.orders = payload;
    },
    PostOrders(state, payload) {
      state.orders.findIndex((e) => e.date == payload.date);
    },
    setDayComplete(state, payload) {
      state.dayComplete = payload;
    },
  },
  actions: {
    async GetOrders({ commit, dispatch, getters }) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        if (!getters.DISHES_TRANSLATE) await dispatch("DishesTranslate");
        let daysArr = [];

        let userId = getters.SELECT_USER?.id || getters.USER.id;
        let url = getters.ROUTERS.ordersList.replace("{companyId}", getters.USER.company.id).replace("{employee}", userId);
        let { data } = await api.get(url);

        let i = 0;
        while (daysArr?.length < 6) {
          let now = new Date();
          let dayMSec = now.setDate(now.getDate() + i);
          let day = new Date(dayMSec);
          let daysShortNames = ["Вс", getters.DISHES_TRANSLATE.common.btn.days.short.mon, getters.DISHES_TRANSLATE.common.btn.days.short.tue, getters.DISHES_TRANSLATE.common.btn.days.short.wed, getters.DISHES_TRANSLATE.common.btn.days.short._thu, getters.DISHES_TRANSLATE.common.btn.days.short.fri, "Сб"];
          let daysNames = ["Воскресенье", getters.DISHES_TRANSLATE.common.btn.days.long.mon, getters.DISHES_TRANSLATE.common.btn.days.long.tue, getters.DISHES_TRANSLATE.common.btn.days.long.wed, getters.DISHES_TRANSLATE.common.btn.days.long._thu, getters.DISHES_TRANSLATE.common.btn.days.long.fri, "Суббота"];

          const isAvailableDay = Boolean(undefined !== Object.values(getters.USER?.company?.work_days)?.find((workDay) => workDay === day.getDay()));

          let year = day.getFullYear();
          let month = day.getMonth() + 1 < 10 ? "0" + (day.getMonth() + 1) : day.getMonth() + 1;
          let date = day.getDate() < 10 ? "0" + day.getDate() : day.getDate();
          let formatDate = `${year}-${month}-${date}`;

          const isOrderExist = Boolean(undefined !== data?.find((orderItem) => {
            return orderItem.date === formatDate && orderItem.options.in_work;
          }));

          if (isAvailableDay || isOrderExist) {
            daysArr.push({
              date: formatDate,
              id: null,
              items: [],
              name: daysNames[day.getDay()],
              short_name: daysShortNames[day.getDay()],
              over_limit: null,
              price: null,
            });
          }

          i++;
        }



        daysArr.forEach((e) => {
          let index = data.findIndex((r) => r.date == e.date);
          if (index != -1) {
            e.items = data[index].items;
            e.id = data[index].id;
            e.over_limit = data[index].over_limit;
            e.price = data[index].price;
            e.options = data[index].options;
          }
        });

        commit("GetOrders", daysArr);
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async PostOrders({ commit, dispatch, getters }, payload) {
      try {
        let userId = getters.SELECT_USER?.id || getters.USER.id;
        let url = getters.ROUTERS.ordersCreate.replace("{companyId}", getters.USER.company.id).replace("{employee}", userId);
        let { data } = await api.post(url, payload);
        commit("PostOrders", data);
        dispatch("SummonAlert", { message: getters.DISHES_TRANSLATE?.alerts?.dishes?.add?.success });
      } catch (error) {
        commit("setDayComplete", true);
        dispatch("SummonAlert", { message: getters.DISHES_TRANSLATE?.alerts?.dishes?.add?.failed, type: "error" });
      }
    },
    async DeleteOrders({ dispatch, getters }, payload) {
      try {
        let userId = getters.SELECT_USER?.id || getters.USER.id;
        let url = getters.ROUTERS.ordersRemove.replace("{companyId}", getters.USER.company.id).replace("{employee}", userId);
        await api.delete(url, { data: payload.data });
        await dispatch("GetOrders");
        dispatch("SummonAlert", { message: getters.DISHES_TRANSLATE?.alerts?.dishes?.remove?.success });
      } catch (error) {
        dispatch("SummonAlert", { message: getters.DISHES_TRANSLATE?.alerts?.dishes?.remove?.failed, type: "error" });
      }
    },
    async DeleteDish({ dispatch, getters }, payload) {
      try {
        let url = getters.ROUTERS.ordersRemove.replace("{companyId}", payload.company).replace("{employee}", payload.user);
        await api.delete(url, { data: payload.data });
        await dispatch("EmployeesList");
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE?.list?.alert?.employee?.dish + " " + payload.day + " " + getters.EMPLOYEES_TRANSLATE?.list?.alert?.employee?.dish_two, type: "error" });
      } catch (error) {
        dispatch("SummonAlert", { message: getters.DISHES_TRANSLATE?.alerts?.dishes?.remove?.failed, type: "error" });
      }
    },
    async DeleteDayDishes({ dispatch, getters }, payload) {
      try {
        let url = getters.ROUTERS.ordersDestroy.replace("{companyId}", payload.company).replace("{employee}", payload.user).replace("{order}", payload.order_id);
        await api.delete(url);
        await dispatch("EmployeesList");
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE?.list?.alert?.employee?.dishes + " " + payload.day + " " + getters.EMPLOYEES_TRANSLATE?.list?.alert?.employee?.dishes_two, type: "error" });
      } catch (error) {
        dispatch("SummonAlert", { message: getters.DISHES_TRANSLATE?.alerts?.dishes?.remove?.failed, type: "error" });
      }
    },
  },
};
