export default {
  state: {
    id: null,
    infoStep: 0,
    links: false,
  },
  getters: {
    GET_ID: state => {
      return state.id;
    },
    GET_INFO_STEP: state => {
      return state.infoStep;
    },
    LINKS: state => {
      return state.links;
    },
  },
  mutations: {
    Set_Id(state, payload) {
      state.id = payload;
    },
    Set_Info_Step(state, payload) {
      state.infoStep = payload;
    },
    Set_Links(state, payload) {
      state.links = payload;
    },
  }
};
