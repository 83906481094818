<template>
  <Alert></Alert>
  <Navbar class="navbar" v-if="USER" @selectUser="userModal" :id="GET_ID">
    <template v-slot:introduction>
      <InfoMessage v-if="this.USER?.role?.id == 2" right="-20px" top="44px" arrowDir="tr" :class="{ show: GET_INFO_STEP == 1 }">
        <div class="steps-box">
          <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.first }}</span>
          <div class="step-interactive-box">
            <span class="active"></span>
            <span></span>
            <span class="only-mob"></span>
            <span></span>
            <div @click="nextInfoStep" class="step-btn s-text-alert">
              {{ DISHES_TRANSLATE?.tips?.btn?.next }}
            </div>
          </div>
        </div>
      </InfoMessage>
    </template>
  </Navbar>
  <router-view />
</template>

<script>
import Alert from "@/components/alert-setting.vue";
import Navbar from "@/components/navbar";
import InfoMessage from "@/components/parts/info-message";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Alert,
    Navbar,
    InfoMessage
  },
  methods: {
    ...mapActions(["EmployeesList"]),
    async userModal() {
      document.querySelector("body").style.overflow = "hidden";
      this.$store.commit("Set_Choose_Employee", true);
      await this.EmployeesList();
    },
    nextInfoStep() {
      if (this.USER) {
        if (this.USER?.role?.id == 2) {
          this.$store.commit("Set_Info_Step", 2);
        }
      }
    }
  },
  computed: {
    ...mapGetters(["USER", "GET_ID", "GET_INFO_STEP", "DISHES_TRANSLATE"])
  },
  async mounted() {
    if (navigator.userAgent.indexOf("iPhone") > 0 || navigator.userAgent.indexOf("iPad") > 0) document.querySelector("body").classList.add("ios");
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}
body {
  overflow-x: hidden;
}
#app {
  height: 100%;
}
.navbar {
  .step-btn {
    background: var(--white-100);
    border-radius: 8px;
    height: 48px;
    cursor: pointer;
    text-transform: uppercase;
    color: var(--blue-main);
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    text-decoration: none;
  }
  .step-text {
    text-transform: none;
  }
  .step-interactive-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    span {
      height: 4px;
      width: 24px;
      border-radius: 10px;
      background: var(--white-40);
      margin-right: 8px;
      &.only-mob {
        display: none;
      }
      &.active {
        background: var(--white-100);
      }
    }
  }
  @media (max-width: 764px) {
    .step-interactive-box span.only-mob {
      display: block;
    }
  }
}
</style>
