import api from "@/helpers/api";

export default {
  state: {
    preferredDishes: null,
    dishes: null,
    oneDish: null,
    dishesCategories: null,
    oneDishesCategory: null,
    selectUser: null,
    recommended: null,
    surchargeChecker: false,
  },
  getters: {
    ONE_DISH: state => {
      return state.oneDish;
    },
    PREFERRED_DISHES: state => {
      return state.preferredDishes;
    },
    DISHES: state => {
      return state.dishes;
    },
    DISHES_CATEGORIES: state => {
      return state.dishesCategories;
    },
    ONE_DISHES_CATEGORY: state => {
      return state.oneDishesCategory;
    },
    SELECT_USER: state => {
      return state.selectUser;
    },
    RECOMMENDED_DISHES: state => {
      return state.recommended;
    },
    SURCHARGE_CHECKER: state => {
      return state.surchargeChecker;
    }
  },
  mutations: {
    SetSurchargeChecker(state, payload) {
      state.surchargeChecker = payload;
    },
    GetDish(state, payload) {
      state.oneDish = payload;
    },
    GetAllDishes(state, payload) {
      state.dishes = payload;
    },
    GetPreferredDishes(state, payload) {
      state.preferredDishes = payload;
    },
    GetDishesCategories(state, payload) {
      state.dishesCategories = payload;
    },
    GetDishesCategory(state, payload) {
      state.oneDishesCategory = payload;
    },
    SelectUser(state, payload) {
      state.selectUser = payload;
    },
    GetRecommendedDishes(state, payload) {
      state.recommended = payload;
    }
  },
  actions: {
    async GetRecommendedDishes({ getters, dispatch, commit }) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let userId = getters.SELECT_USER?.id || getters.USER.id;
        let url = getters.ROUTERS.dishesRecommended.replace("{companyId}", getters.USER.company.id).replace("{employee}", userId);
        let { data } = await api.get(url);
        commit("GetRecommendedDishes", data);
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async GetAllDishes({ getters, dispatch, commit }, is_sort = "") {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let userId = getters.SELECT_USER?.id || getters.USER.id;
        let url = getters.ROUTERS.dishesIndex.replace("{companyId}", getters.USER.company.id).replace("{employee}", userId);
        const { data } = await api.get(url + is_sort);
        commit("GetAllDishes", data.dishes);
        commit("GetPreferredDishes", data.popular);
        await dispatch("GetDishesCategories");
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async GetDishesCategories({ getters, dispatch, commit }) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let url = getters.ROUTERS.dishesCategoriesIndex.replace("{companyId}", getters.USER.company.id);
        let { data } = await api.get(url);
        data = data.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
        commit("GetDishesCategories", data);
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async GetDish({ getters, dispatch, commit }, dish_id) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let url = getters.ROUTERS.dishesShow.replace("{companyId}", getters.USER.company.id).replace("{dish}", dish_id);
        const { data } = await api.get(url);
        commit("GetDish", data);
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async GetDishesCategory({ getters, dispatch, commit }, category_id) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let url = getters.ROUTERS.dishesCategoriesShow.replace("{companyId}", getters.USER.company.id).replace("{category}", category_id);
        const { data } = await api.get(url);
        commit("GetDishesCategory", data);
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async SelectUser({ commit }, payload) {
      commit("SelectUser", payload);
    }
  }
};
