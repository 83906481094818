import api from "@/helpers/api";
export default {
  state: {
    settingsTranslate: null,
    registerTranslate: null,
    employeesTranslate: null,
    authTranslate: null,
    otherTranslate: null,
    errorPageTranslate: null,
    dishesTranslate: null,
    validationTranslate: null,
    ordersHistoryTranslate: null
  },
  getters: {
    DISHES_TRANSLATE: state => {
      return state.dishesTranslate;
    },
    SETTINGS_TRANSLATE: state => {
      return state.settingsTranslate;
    },
    REGISTER_TRANSLATE: state => {
      return state.registerTranslate;
    },
    EMPLOYEES_TRANSLATE: state => {
      return state.employeesTranslate;
    },
    AUTH_TRANSLATE: state => {
      return state.authTranslate;
    },
    OTHER_TRANSLATE: state => {
      return state.otherTranslate;
    },
    ERROR_PAGE_TRANSLATE: state => {
      return state.errorPageTranslate;
    },
    VALIDATION_TRANSLATE: state => {
      return state.validationTranslate;
    },
    ORDERS_HISTORY_TRANSLATE: state => {
      return state.ordersHistoryTranslate;
    }
  },
  mutations: {
    DishesTranslate(state, payload) {
      state.dishesTranslate = payload;
    },
    SettingsTranslate(state, payload) {
      state.settingsTranslate = payload;
    },
    RegisterTranslate(state, payload) {
      state.registerTranslate = payload;
    },
    EmployeesTranslate(state, payload) {
      state.employeesTranslate = payload;
    },
    AuthTranslate(state, payload) {
      state.authTranslate = payload;
    },
    OtherTranslate(state, payload) {
      state.otherTranslate = payload;
    },
    ErrorPageTranslate(state, payload) {
      state.errorPageTranslate = payload;
    },
    ValidationTranslate(state, payload) {
      state.validationTranslate = payload;
    },
    OrdersHistoryTranslate(state, payload) {
      state.ordersHistoryTranslate = payload;
    }
  },
  actions: {
    async DishesTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "dishes");
      let { data } = await api.get(url);
      commit("DishesTranslate", data);
    },
    async SettingsTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "settings");
      let { data } = await api.get(url);
      commit("SettingsTranslate", data);
    },
    async RegisterTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "request");
      let { data } = await api.get(url);
      commit("RegisterTranslate", data);
    },
    async EmployeesTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "employees-list");
      let { data } = await api.get(url);
      commit("EmployeesTranslate", data);
    },
    async AuthTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "auth");
      let { data } = await api.get(url);
      commit("AuthTranslate", data);
    },
    async OtherTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "other");
      let { data } = await api.get(url);
      commit("OtherTranslate", data);
    },
    async ErrorPageTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "404");
      let { data } = await api.get(url);
      commit("ErrorPageTranslate", data);
    },
    async ValidationTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "errors");
      let { data } = await api.get(url);
      commit("ValidationTranslate", data);
    },
    async OrdersHistoryTranslate({ commit, getters }) {
      let url = getters.ROUTERS.translatePage.replace("{page}", "order-history");
      let { data } = await api.get(url);
      commit("OrdersHistoryTranslate", data);
    }
  }
};
