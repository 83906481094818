import api from "@/helpers/api";

export default {
  actions: {
    async RegisterCompany({ dispatch, getters }, payload) {
      try {
        if (!getters.ROUTERS) await dispatch("GetRouters");
        let { data } = await api.post(getters.ROUTERS.companiesRegister, payload);
        return "success";
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
        return "error";
      }
    }
  }
};
