import api from "@/helpers/api";

export default {
  state: {
    exportPeriods: null,
    exportId: null,
    exportData: null
  },
  getters: {
    GET_EXPORT_PERIODS: state => {
      return state.exportPeriods;
    },
    GET_EXPORT_ID: state => {
      return state.exportId;
    },
    GET_EXPORT_DATA: state => {
      return state.exportData;
    }
  },
  mutations: {
    Set_Export_Periods(state, payload) {
      state.exportPeriods = payload;
    },
    Get_Export_Id(state, payload) {
      state.exportId = payload;
    },
    Get_Export_Data(state, payload) {
      state.exportData = payload;
    }
  },
  actions: {
    async SetExportPeriods({ commit, dispatch, getters }) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        if (getters.USER.role.id == 2) {
          let url = getters.ROUTERS.ordersExportPeriods.replace("{companyId}", getters.USER.company.id);
          let res = await api.get(url);
          commit("Set_Export_Periods", res.data);
        }
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async GetExportId({ commit, dispatch, getters }, payload) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        let url = getters.ROUTERS.ordersExport.replace("{companyId}", getters.USER.company.id) + `?from=${payload.from}&to=${payload.to}&withoutAdditionalPayment=${payload.flag}`;
        let { data } = await api.get(url);
        commit("Get_Export_Id", data.export_id);
      } catch (error) {
        console.log(error.response);
      }
    },
    async GetExportStatus({ commit, dispatch, getters }) {
      try {
        let url = getters.ROUTERS.ordersExportStatus.replace("{companyId}", getters.USER.company.id).replace("{id}", getters.GET_EXPORT_ID);
        let { data } = await api.get(url);
        commit("Get_Export_Data", data.status);
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async SetExportOrder({ dispatch, getters }, payload) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        if (!getters.ORDERS_HISTORY_TRANSLATE) await dispatch("OrdersHistoryTranslate");
        if (getters.USER.role.id == 2) {
          let url = getters.ROUTERS.ordersExportDownload.replace("{companyId}", getters.USER.company.id).replace("{id}", getters.GET_EXPORT_ID);
          let res = await api.get(url, { responseType: "blob" });
          await dispatch("SummonAlert", { message: getters.ORDERS_HISTORY_TRANSLATE?.alerts?.export?.success });
          let saveData = (function() {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            return function(data, fileName) {
              let json = data,
                blob = new Blob([json], { type: res.headers["content-type"] }),
                url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          })();

          let { data } = res,
            fileName = `orders_${payload.from}__${payload.to}.xlsx`;
          saveData(data, fileName);
        }
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.ORDERS_HISTORY_TRANSLATE?.alerts?.export?.failed, type: "error" });
      }
    }
  }
};
