import api from "@/helpers/api";

export default {
  state: {
    link: null,
    cards: null,
    timer: null
  },
  getters: {
    GET_CARDS: state => {
      return state.cards;
    },
    GET_PAYMENT_LINK: state => {
      return state.link;
    },
    GET_TIMER: state => {
      return state.timer;
    },
  },
  mutations: {
    getCards(state, payload) {
      state.cards = payload;
    },
    setPaymentLink(state, payload) {
      state.link = payload;
    },
    setTimer(state, payload) {
      state.timer = payload;
    },
  },
  actions: {
    async CheckTimer({ dispatch, getters, commit }, payload) {
      try {
        let url = getters.ROUTERS.invoicesCheckPayment;
        let { data } = await api.post(url, payload.data);
        await commit("setTimer", data);
      } catch (error) {
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async AddCard({ dispatch, getters }, payload) {
      try {
        if (!getters.SETTINGS_TRANSLATE) await dispatch("SettingsTranslate");
        let url = getters.ROUTERS.paymentCardAdd;
        let { data } = await api.post(url, payload.data);
        await dispatch("GetCards");
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE.payment.card.alerts.success });
      } catch (error) {
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE.payment.card.alerts.error, type: "error" });
      }
    },
    async RemoveCard({ dispatch, getters }, payload) {
      try {
        let url = getters.ROUTERS.paymentCardRemove;
        await api.post(url, payload.data);
        await dispatch("GetCards");
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE.payment.card.alerts.remove });
      } catch (error) {
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async GetCards({ commit, getters, dispatch }) {
      try {
        let url = getters.ROUTERS.paymentCardList;
        let { data } = await api.get(url);
        commit("getCards", data);
      } catch (error) {
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async SetPaymentLink({ commit, getters, dispatch }, payload) {
      try {
        let url = getters.ROUTERS.invoicesPayOverLimit;
        let { data } = await api.post(url, payload.data);
        await commit("setPaymentLink", data);
        if (getters.GET_PAYMENT_LINK.is_error) {
          dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
        } else {
          if (getters.GET_PAYMENT_LINK.is_card) dispatch("SummonAlert", { message: "Платеж успешно проведен"});
        }
      } catch (error) {
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    }
  }
};
