import api from "@/helpers/api";

export default {
  state: {
    homeData: null,
    dishList: null,
  },
  getters: {
    HOME_DATA: state => {
      return state.homeData;
    },
    MAIN_DISH_LIST: state => {
      return state.dishList;
    }
  },
  mutations: {
    updateHomeData(state, payload) {
      state.homeData = payload;
    },
    setMainDishList(state, payload) {
      state.dishList = payload;
    },
  },
  actions: {
    async updateHomeData({ commit, dispatch, getters }) {
      try {
        if (!getters.VALIDATION_TRANSLATE) await dispatch("ValidationTranslate");
        let url = getters.ROUTERS.translatePage.replace("{page}", "home");
        let { data } = await api.get(url);
        commit("updateHomeData", data);
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async SetMainDishList({ commit, dispatch, getters }) {
      try {
        let url = getters.ROUTERS.dishesMainList;
        let { data } = await api.get(url);
        let dishArr = Object.values(data);
        let newArr = [];
        let counter = 0;
        dishArr.forEach(subArr => {
          subArr.forEach(item => {
            newArr[counter] = item;
            counter++;
          });
        });
        commit("setMainDishList", newArr);
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    }
  }
};
