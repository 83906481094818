import api from "@/helpers/api";

export default {
  state: {
    roles: null
  },
  getters: {
    GET_ROLES: state => {
      return state.roles;
    }
  },
  mutations: {
    SET_ROLES(state, payload) {
      state.roles = payload;
    }
  },
  actions: {
    async Roles({ dispatch, commit, getters }) {
      try {
        let { data } = await api.get(getters.ROUTERS.rolesIndex);
        commit("SET_ROLES", data);
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    }
  }
};
