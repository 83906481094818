import api from "@/helpers/api";

export default {
  state: {
    employees: {
      user: null
    },
    chooseEmployee: false,
    me: null
  },
  getters: {
    GET_EMPLOYEES: state => {
      return state.employees;
    },
    GET_ME: state => {
      return state.me;
    },
    CHOOSE_EMPLOYEE: state => {
      return state.chooseEmployee;
    }
  },
  mutations: {
    Set_Employees(state, payload) {
      state.employees = payload;
    },
    Set_Me(state, payload) {
      state.me = payload;
    },
    Set_Choose_Employee(state, payload) {
      state.chooseEmployee = payload;
    },
  },
  actions: {
    async EmployeesList({ commit, dispatch, getters }) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        if (getters.USER.role.id == 2) {
          if (!getters.DISHES_TRANSLATE) await dispatch("DishesTranslate");
          if (!getters.EMPLOYEES_TRANSLATE) await dispatch("EmployeesTranslate");
          let url = getters.ROUTERS.employeesIndex.replace("{companyId}", getters.USER.company.id);
          let res = await api.get(url);
          let sortRes = res.data;
          sortRes.forEach(item => {
            let daysArr = [];
            let orderCount = 0;
            let firstDay = 0;
            for (let i = 1; i < 8; i++) {
              let now = new Date();
              let dayMSec = now.setDate(now.getDate() + i);
              let day = new Date(dayMSec);
              let daysShortNames = ["Вс", getters.DISHES_TRANSLATE.common.btn.days.short.mon, getters.DISHES_TRANSLATE.common.btn.days.short.tue, getters.DISHES_TRANSLATE.common.btn.days.short.wed, getters.DISHES_TRANSLATE.common.btn.days.short._thu, getters.DISHES_TRANSLATE.common.btn.days.short.fri, "Сб"];
              let daysNames = ["Воскресенье", getters.EMPLOYEES_TRANSLATE.list.days.declination.mon, getters.EMPLOYEES_TRANSLATE.list.days.declination.tue, getters.EMPLOYEES_TRANSLATE.list.days.declination.wed, getters.EMPLOYEES_TRANSLATE.list.days.declination.thu, getters.EMPLOYEES_TRANSLATE.list.days.declination.fri, "Суббота"];
              if (day.getDay() != 0 && day.getDay() != 6) {
                let year = day.getFullYear();
                let month = day.getMonth() + 1 < 10 ? "0" + (day.getMonth() + 1) : day.getMonth() + 1;
                let date = day.getDate() < 10 ? "0" + day.getDate() : day.getDate();
                let formatDate = `${year}-${month}-${date}`;
                daysArr.push({
                  period: (day.getDate() > 9 ? day.getDate() : `0${day.getDate()}`) + "." + (day.getMonth() + 1 > 9 ? day.getMonth() + 1 : `0${day.getMonth() + 1}`),
                  items: item.orders.filter(date => date.date == formatDate),
                  name: daysNames[day.getDay()],
                  short_name: daysShortNames[day.getDay()]
                });
                orderCount = daysArr.filter(items => items.items.length > 0).length;
                let temp = daysArr;
                firstDay = temp.findIndex(item => item.items.length > 0);
              }
            }
            item.days = daysArr;
            item.count = orderCount;
            item.firstDay = firstDay;
          });

          let temp = sortRes.sort((a, b) => (a.is_guest > b.is_guest ? 1 : -1)).pop();
          sortRes.sort((a, b) => (`${a.name}${a.last_name}` > `${b.name}${b.last_name}` ? 1 : -1));
          sortRes.push(temp);

          commit("Set_Employees", sortRes);
          commit("Set_Me", getters.USER.id);
        }
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    }
  }
};
