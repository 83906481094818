import api from "@/helpers/api";

export default {
  state: {
    emailToken: null
  },
  getters: {
    EMAIL_TOKEN: state => {
      return state.emailToken;
    }
  },
  mutations: {
    updateEmailToken(state, payload) {
      state.emailToken = payload;
    }
  },
  actions: {
    UpdateEmailToken({ commit }, payload) {
      commit("updateEmailToken", payload);
    },
    async PassCreate({ dispatch, getters }, payload) {
      try {
        if (!getters.ROUTERS) await dispatch("GetRouters");
        payload.token = getters.EMAIL_TOKEN;
        let res = await api.post(getters.ROUTERS.passwordSet, payload);
        return "success";
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async PassRestore({ dispatch, getters }, payload) {
      try {
        if (!getters.ROUTERS) await dispatch("GetRouters");
        payload.token = getters.EMAIL_TOKEN;
        let res = await api.post(getters.ROUTERS.passwordRestore, payload);
        return "success";
      } catch (error) {
        console.log(error);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    }
  }
};
