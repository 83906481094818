import api from "@/helpers/api";

export default {
  state: {
    company: null,
    preferencesAll: null,
    preference: null,
    permissionUserEdit: true
  },
  getters: {
    COMPANY: state => {
      return state.company;
    },
    PREFERENCES_ALL: state => {
      return state.preferencesAll;
    },
    ONE_PREFERENCE: state => {
      return state.preference;
    },
    PERMISSION_USER_EDIT: state => {
      return state.permissionUserEdit;
    }
  },
  mutations: {
    UpdateCompany(state, payload) {
      state.company = payload;
    },
    GetAllPreferences(state, payload) {
      state.preferencesAll = payload;
    },
    GetPreference(state, payload) {
      state.preference = payload;
    },
    SetPermissionUserEdit(state, payload) {
      state.permissionUserEdit = payload;
    }
  },
  actions: {
    async ChangePassword({ dispatch, getters }, payload) {
      try {
        await api.post(getters.ROUTERS.passwordChange, payload);
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.settings?.alert?.password?.success });
      } catch (error) {
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.settings?.alert?.password?.failed, type: "error" });
        console.log(error);
      }
    },
    async UpdateCompany({ dispatch, commit, getters }) {
      if (!getters.USER) await dispatch("AboutMe");
      try {
        let { data } = await api.get(getters.ROUTERS.companiesShow.replace("{id}", getters.USER.company.id));
        data.limits.sort((a, b) => (a.sum > b.sum ? 1 : -1));
        commit("UpdateCompany", data);
      } catch (error) {
        console.log(error);
      }
    },
    async EditCompanyAct({ dispatch, commit, getters }, payload) {
      if (!getters.USER) await dispatch("AboutMe");
      try {
        let { data } = await api.put(getters.ROUTERS.companiesUpdate.replace("{id}", getters.USER.company.id), payload);
        commit("UpdateCompany", data);
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE?.modals.company.alert.success });
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE?.modals.company.alert.failed, type: "error" });
      }
    },
    async SendReview({ dispatch, getters }, payload) {
      try {
        await api.post(getters.ROUTERS.employeesReview, payload);
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE?.alerts?.review?.success });
      } catch (error) {
        console.log(error.response);
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE?.alerts?.review?.failed, type: "error" });
      }
    },
    async GetPreference({ commit, getters }, preference_id) {
      try {
        let url = getters.ROUTERS.preferencesShow.replace("{preference}", preference_id);
        const { data } = await api.get(url);
        commit("GetPreference", data);
      } catch (error) {
        console.log(error);
      }
    },
    async GetAllPreferences({ dispatch, commit, getters }) {
      try {
        if (!getters.SETTINGS_TRANSLATE) await dispatch("SettingsTranslate");
        const { data } = await api.get(getters.ROUTERS.preferencesIndex);

        let allergensArr = data.filter(e => e.type == "allergen");
        let othersArr = data.filter(e => e.type == "other");
        let categoriesArr = [
          {
            name: getters.SETTINGS_TRANSLATE?.allergens?.food,
            items: allergensArr
          },
          {
            name: getters.SETTINGS_TRANSLATE?.allergens?.another,
            items: othersArr
          }
        ];
        await commit("GetAllPreferences", categoriesArr);
      } catch (error) {
        console.log(error);
      }
    },
    async EditEmployeeSettings({ dispatch, getters, commit }, payload) {
      try {
        if (!getters.EMPLOYEES_TRANSLATE) await dispatch("EmployeesTranslate");
        let url = getters.ROUTERS.employeesUpdate.replace("{employee}", payload.user).replace("{companyId}", payload.company);
        if (getters.PERMISSION_USER_EDIT) {
          commit("SetPermissionUserEdit", false);
          let { data } = await api.put(url, payload.temp);
          if (getters.SELECT_USER) {
            dispatch("SelectUser", data);
          } else {
            commit("User", data);
          }
          commit("SetPermissionUserEdit", true);
          dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.edit.success });
          return true;
        }
      } catch (error) {
        console.log(error);
        commit("SetPermissionUserEdit", true);
        dispatch("SummonAlert", { message: getters.EMPLOYEES_TRANSLATE.list.alert.employee.edit.failed, type: "error" });
        return false;
      }
    }
  }
};
