import api from "@/helpers/api";

export default {
  state: {
    seo: null
  },
  getters: {
    SEO: state => {
      return state.seo;
    }
  },
  mutations: {
    GetSeo(state, payload) {
      state.seo = payload;
    }
  },
  actions: {
    async GetSeo({ commit, getters }) {
      try {
        let url = getters.ROUTERS.seoMeta.replace("{page?}", "");
        let urlSrc = getters.ROUTERS.seoScripts.replace("{page?}", "");
        let { data } = await api.get(url);
        let res = await api.get(urlSrc);
        if (res?.data?.main?.[0]?.body?.top) document.body.insertAdjacentHTML("afterBegin", res?.data?.main?.[0]?.body?.top);
        if (res?.data?.main?.[0]?.body?.bottom) document.body.insertAdjacentHTML("beforeEnd", res?.data?.main?.[0]?.body?.bottom);
        if (res?.data?.main?.[0]?.head) document.head.insertAdjacentHTML("beforeEnd", res?.data?.main?.[0]?.head);
        commit("GetSeo", data);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
