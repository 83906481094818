import api from "@/helpers/api";

export default {
  state: {
    limits: null
  },
  getters: {
    LIMITS: state => {
      return state.limits;
    }
  },
  mutations: {
    updateLimits(state, payload) {
      state.limits = payload;
    }
  },
  actions: {
    async GetLimits({ commit, getters, dispatch }) {
      try {
        if (!getters.USER) await dispatch("AboutMe");
        if (getters.USER.role.id != 2) return false;
        let url = getters.ROUTERS.limitsIndex.replace("{companyId}", getters.USER.company.id);
        let { data } = await api.get(url);
        commit("updateLimits", data);
      } catch (error) {
        dispatch("SummonAlert", { message: getters.VALIDATION_TRANSLATE?.common?.text, type: "error" });
      }
    },
    async EditLimits({ getters, dispatch }, payload) {
      try {
        if (!getters.USER) await dispatch("AboutMe");

        let putPromises = payload.put.map(element => {
          let url = getters.ROUTERS.limitsUpdate.replace("{companyId}", getters.USER.company.id).replace("{limit}", element.id);
          return api.put(url, { sum: element.sum, is_guest: !!element.is_guest });
        });

        let delPromises = payload.del.map(element => {
          let url = getters.ROUTERS.limitsDestroy.replace("{companyId}", getters.USER.company.id).replace("{limit}", element.id);
          return api.delete(url);
        });

        let postPromises = payload.post.map(element => {
          let url = getters.ROUTERS.limitsStore.replace("{companyId}", getters.USER.company.id);
          return api.post(url, { sum: element.sum, is_guest: !!element.is_guest });
        });

        let PutRes = await Promise.all([...putPromises, ...delPromises, ...postPromises]);

        dispatch("GetLimits");

        if (PutRes.includes(undefined)) {
          dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE?.modals.limits.alert.failed, type: "error" });
        } else {
          dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE?.modals.limits.alert.success });
        }
      } catch (error) {
        dispatch("SummonAlert", { message: getters.SETTINGS_TRANSLATE?.modals.limits.alert.failed, type: "error" });
      }
    }
  }
};
